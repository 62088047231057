h3{
 color: rgb(51, 20, 15)
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.navbb{
  background-color: rgb(252,247,228);
}

.siteLogo{
  transition: transform 0.5s ease;

}

.siteLogo:hover{
  transform: rotate(12deg);
}

.navwords{
  color:rgb(51, 20, 15,.55);
  text-decoration: none;
  padding: 1em;
  font-size: 19px;
  font-weight: bold;
}

.navwords:hover{
  color:rgb(51, 20, 15);
  background-color: rgb(51, 20, 15,.25);
  border-radius: 10px;

}

.custom-navbar-bg {
  background-color: #4CAF50; /* Your desired background color */
}



.App-header {
  background-color: rgb(252,247,228);
  min-height: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-weight: bold;
  font-family: 'Roboto',sans-serif;
}

.nav-styling{

  font-size: 5em;
  font-family: 'Roboto';

  color: rgb(51, 20, 15);

}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.swapButton {




  padding: 15px 20px ; /* Increased padding for a more prominent look */
  font-size: 15px; /* Slightly larger text for better readability */
  background: rgb(51, 20, 15);
  color: rgb(229, 229, 229);
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  border: none;
  border-radius: 30px; /* More pronounced rounded corners */
  cursor: pointer;
  display: inline-block;
  transition: transform 0.1s ease, box-shadow 0.1s ease, background 0.2s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);

  /* Centering the button */
  position: relative; /* Adjusted to maintain the button's positioning context */
  top: 50%; left: 50%;
  transform: translate(-50%, -50%); /* Corrected centering technique */
  margin-top: 25px; /* Adjusted for visual alignment */

}

.swapButton:hover {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  transform: translate(-50%, -50%) scale(1.05); /* Slight scale on hover for interactive effect */
}



.switch-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 95%;
  max-width: 600px;
}

.input-field {
  border-radius: 8px;
  border: 1px solid #ddd;
  padding: 25px 15px;
  margin: 10px 0;
  outline: none;
  width: 85%;
  flex-direction: column;


  font-weight: bold;
  font-size: medium;

 height: 2em;

  border: none;

  box-shadow: inset 8px 5px 10px rgba(0, 0, 0, 0.08);


}


.switch-button {
  border-radius: 50%;
  border: none;
  padding: 10px 15px;
  margin: 10px 0;
  background-color: rgb(51, 20, 15);
  color: white;
  cursor: pointer;
  outline: none;
  width: 50px;
  height: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 25px;
  transition: transform 0.2s ease; /* Animation duration and timing function */
  transform-origin: center; /* Set the transform origin to the center */
}

.switch-button:hover {
  transform: rotate(180deg); /* Rotate 180 degrees on hover */
}

.navs {
  cursor: pointer; /* Changes the mouse cursor to a pointer to indicate clickable */
  border-radius: 10px; /* Rounded corners */
  transition: all 0.3s ease; /* Smooth transition for the hover effect */
}

.navs:hover {
  background-color: rgba(128, 0, 128, 0.5); /* Semi-transparent purple background */
  backdrop-filter: blur(5px); /* Frosted glass effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: adds a subtle shadow for depth */
}


.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* Additional styling for the container if needed */
  width: 100%;
  height: 50vh; /* Adjust as needed */
}

.video-placeholder {
  width: 300px; /* Adjust width as needed */
  height: 200px; /* Adjust height as needed */
  background-color: black;
}
.bnb-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.input-with-image {
  display: flex;
  align-items: center;
}


.buttonback{
  border: none; /* Remove button border */
  padding: 0; /* Remove button padding */
  background: transparent; /* Set button background to transparent */
  cursor: pointer;
  outline: none;
}


.field-with-image {
  display: flex;

  width: 100%;
}

.field-with-image img {
  margin-right: 15px;
  width: 3em;
  height:3em;
}


.button-container {
  position: relative;
  width: 100px;
  height: 100px;
  }

.button-image {
  position: absolute;
  .button-container:hover {
    transform: rotate(40deg); /* Rotate 180 degrees on hover */
  }

  transform: translate(-100%, 40%);
  width: 50%;
  pointer-events: none;
  height: auto;
}
.navbar-color{
  background-color: rgb(252, 247, 228);
  color: rgb(51, 20, 15);
}


.logo-container {
  width: 25%;
  height: auto;
  padding-bottom: 0;

}

.connect-button{

  padding: 10px 15px; /* Adjusted padding for optimal spacing */
  background: linear-gradient(145deg, #f9b435, rgb(252, 247, 228));
  color: rgb(51, 20, 15);
  font-weight: bold;
  text-transform: uppercase; /* Assuming you want to keep the text style consistent */
  cursor: pointer;
  display: inline-block; /* Ensures the width can adjust to content */
  width: auto; /* Allows the button to expand based on its content */
  height: 50px; /* Fixed height for consistency */
  margin-top: 20px;
  margin-bottom: 20px;
  border: none; /* Removes any border, assuming the last 'border: solid;' was not intended */
  border-radius: 31px; /* Keeps the original percentage-based rounded corners */
  outline: none; /* Removes the outline to focus on the custom styles */
  transition: all 0.3s ease; /* Smooth transition for hover effects */

  /* Enhanced hover effect */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  font-family: 'Roboto',sans-serif;
}

.connect-button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* More pronounced shadow on hover */
  transform: scale(1.05); /* Slight increase in size to indicate interactivity */
}



.connect-button-clicked {
  padding: 10px 15px; /* Adjusted padding for optimal spacing */
  background: linear-gradient(145deg, rgb(51, 20, 15), #7b6b57);
  color: #f9b435;
  font-weight: bold;
  text-transform: uppercase; /* Assuming you want to keep the text style consistent */
  cursor: pointer;
  display: inline-block; /* Ensures the width can adjust to content */
  width: auto; /* Allows the button to expand based on its content */
  height: 50px; /* Fixed height for consistency */
  margin-top: 20px;
  margin-bottom: 20px;
  border: none; /* Removes any border, assuming the last 'border: solid;' was not intended */
  border-radius: 31px; /* Keeps the original percentage-based rounded corners */
  outline: none; /* Removes the outline to focus on the custom styles */
  transition: all 0.3s ease; /* Smooth transition for hover effects */}





.header {

    background-color: rgb(252,247,228);
    min-height: 15vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    padding-left: 75vh;

  }


.connect-button-container{
 position:absolute;
 right:5em;

}

.link-container{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;

}

.swap-link{
  flex:1;
  margin:2em;
  cursor:pointer;
}

.liquidity-link {
  flex:2;
  margin:2em;
  cursor:pointer;
}



.liquidity-background {
  background-color: rgb(255, 210, 147);
  border-radius: 5%;
  width: 25%;
  padding: 1em;


}


.nftimg-container{
  position: relative;
  display: inline-block;
}

.nftimg-container img {
  display: block;
  width: 10em; /* Adjust as necessary */
  height:10em;
  border-radius: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.nftimg-container img::after{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.2));
  opacity: 0.7; /* Increase opacity for a more noticeable effect */
  z-index: 2;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds shadow for depth */
  border: 7px solid rgba(255, 255, 255, 0.3); /* Adds a subtle border */
  backdrop-filter: blur(2px); /* Optional: adds a blur effect to the overlay */
}

.nftimg-container img::hover{
  transform: scale(1.05); 
}

.image-container {
  position: relative;
  display: inline-block;
}

.image-container img {
  display: block;
  width: 15em; /* Adjust as necessary */
  height:15em;
  border-radius: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.2));
  opacity: 0.7; /* Increase opacity for a more noticeable effect */
  z-index: 2;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adds shadow for depth */
  border: 7px solid rgba(255, 255, 255, 0.3); /* Adds a subtle border */
  backdrop-filter: blur(2px); /* Optional: adds a blur effect to the overlay */
}



.image-container:hover::after {
  /* Reduce or remove the effects on hover */
  opacity: 0; /* Makes the overlay fully transparent */
  backdrop-filter: none; /* Removes the blur effect */
}

.image-container:hover img {
  transform: scale(1.05); /* Optional: slightly scale the image for emphasis */
}

.Swap-Background {
  background-color: rgb(249, 189, 76);
  border-radius: 10%;
  width: 20em;
  height: 25%;
  padding: 2em;
  box-shadow: 12px 12px 2px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}





.image-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 10px;
  gap: 10px; /* Space between images */
}

.image-item {
  flex: 1;
  max-width: 50%;
  height: 15em;
  object-fit: cover;
  border-radius: 10px;
}
:root{
  --wui-color-accent-100:grey;
  --wui-color-inverse-100:grey
}
